import React, { useState, useEffect } from "react";
import * as Loader from "react-loader-spinner";
import CountUp from "react-countup";
import "../App.scss";


const LoadingScreen = () => {
  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowSpinner(false);
    }, 4000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      {showSpinner && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            zIndex: 9999,
          }}
        >
          {/* <Loader.ProgressBar
            borderColor="#000000"
            barColor="#7C7A7A"
            height={40}
            width={40}
          /> */}
          <CountUp start={100} end={0} duration={4} size={10} />
          <p className="load-p">
            {`Please use 2 fingers while browsing for the full 3d experience. \nThank you for taking the time to look at my portfolio.`}
          </p>
        </div>
      )}
    </>
  );
};

export default LoadingScreen;
