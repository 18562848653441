import React from "react";

const Header = () => {
  return (
    <header>
      <div className="logo">
        <span>
          <a href="/">ADONIS</a>
        </span>
      </div>
      <div className="episode">
        <span className="about">
          <a
            href="https://drive.google.com/file/d/1uDNAGIR4MpThTxggxTQUCE5rRHAlU2XG/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            RESUME
          </a>
        </span>
        <span>
          <a href="/projs">PROJECTS</a>
        </span>
      </div>
    </header>
  );
};

export default Header;
