import "../App.scss";
import { Canvas } from "react-three-fiber";
import OpenSans from "../Open Sans ExtraBold_Regular.json";
import React, { useRef, useState, useEffect, Suspense } from "react";
import {
  softShadows,
  OrbitControls,
  Text3D,
  Html,
} from "@react-three/drei";
import Header from "./Header";
// import * as THREE from "three";
import { Lion } from "./Lion";
import LoadingScreen from "./Loading";

softShadows();

const Hire = () => {
  const mesh = useRef(null);

  return (
    <mesh position={[-7.5, 1, 0]} ref={mesh}>
      <Text3D font={OpenSans} size={3}>
        Hire Me
        <meshBasicMaterial color="black" />
      </Text3D>
    </mesh>
  );
};

const Descript = () => {
  return (
    <Html>
      <div className="paragraph-container">
        <p className="paragraph">
          Adonis is a talented Designer/Developer hailing from Brooklyn, NY.
          With a passion for coding, architecture, tech, and AI, Adonis has
          honed his skills in various areas of design and development. Outside
          of work, Adonis enjoys discovering new music and studying the work of
          others, as he has an insatiable hunger for knowledge. Adonis considers
          his taste and eagerness to learn to be his strongest assets, as they
          allow him to continuously grow and develop his craft. With his passion
          and drive, Adonis is sure to continue creating innovative and
          exceptional designs and software in the future.
        </p>
      </div>
    </Html>
  );
};

// const Splay = () => {
//   const mesh = useRef(null);

//   return (
//     <mesh position={[4, -1, 0]} ref={mesh}>
//       <boxBufferGeometry attach="geometry" args={[80, 1, 1]} />
//       <meshStandardMaterial attach="material" color="red" />
//     </mesh>
//   );
// };


function Home() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay to demonstrate the loading screen
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
      <>
     {/* <Suspense fallback={<LoadingScreen />}> */}
      <Header />
      <Canvas colorManagement camera={{ position: [0, 0, 10], fov: 150 }}>
        {/* This light makes things look pretty */}
        <ambientLight intensity={1.3} />
        {/* Our main source of light, also casting our shadow */}
        <directionalLight
          castShadow
          position={[0, 10, 0]}
          intensity={1.5}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-camera-far={50}
          shadow-camera-left={-10}
          shadow-camera-right={10}
          shadow-camera-top={10}
          shadow-camera-bottom={-10}
        />
        {/* A light to help illumnate the spinning boxes */}
        <pointLight position={[-10, 0, -20]} intensity={0.5} />
        <pointLight position={[0, -10, 0]} intensity={1.5} />
        <group>
          {/* This mesh is the plane (The floor) */}
          <mesh
            rotation={[-Math.PI / 2, 0, 0]}
            position={[0, -3, 0]}
            receiveShadow
          >
            <planeBufferGeometry attach="geometry" args={[100, 100]} />
            <shadowMaterial attach="material" opacity={0.3} />
          </mesh>
          <Lion position={[1.3, 6.5, -5]} />
          <Hire />
          <Descript />

          {/* <Splay /> */}
        </group>
        <OrbitControls />
      </Canvas>
      {/* </Suspense> */}
      </>
      )}
    </>
  );
}

export default Home;
