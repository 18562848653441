// /*
// Auto-generated by: https://github.com/pmndrs/gltfjsx
// */

// import React from "react";
// import { useGLTF } from "@react-three/drei";

// export function Chill(props) {
//   const { nodes, materials } = useGLTF("/Chill-3d.glb");
//   return (
//     <group {...props} dispose={null}>
//       <mesh
//         castShadow
//         receiveShadow
//         geometry={nodes.Curve017.geometry}
//         material={materials["SVGMat.010"]}
//         position={[1.49, 1.55, 1.53]}
//         rotation={[1.6, 0.05, -0.03]}
//         scale={51.64}
//       />
//     </group>
//   );
// }

// useGLTF.preload("../public/Chill-3d.glb");
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

export function Chill(props) {
  const { nodes, materials } = useGLTF("/Chill-3d.glb");
  const groupRef = useRef();

  useFrame(() => {
    groupRef.current.rotation.x += 0.01; // adjust the speed of the spin by changing the value
  });

  return (
    <group ref={groupRef} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve017.geometry}
        material={materials["SVGMat.010"]}
        position={[1.49, 1.55, 1.53]}
        scale={51.64}
      />
    </group>
  );
}

useGLTF.preload("../public/Chill-3d.glb");
