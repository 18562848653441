import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

export function Hardies(props) {
  const { nodes, materials } = useGLTF("/simple_screw.glb");
  // Change the color of the material to black
  materials.screwMat.color.set("black");

  const meshRef = useRef();

  useFrame((state, delta) => {
    meshRef.current.rotation.z += 0.01;
  });

  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group position={[0, 0, 4.22]}>
            <mesh
              ref={meshRef}
              castShadow
              receiveShadow
              geometry={nodes.pCylinder12_screwMat_0.geometry}
              material={materials.screwMat}
            />
          </group>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/simple_screw.glb");
