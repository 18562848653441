import "./App.scss";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import * as THREE from "three";
import Projs from "./comp/Projs";
import Home from "./comp/Home";
// import ModelViewer from "./comp/Viewer"
// import ALogo from "./3d/a-3d.glb"


function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/projs" element={<Projs />} />
      </Routes>
    </Router>
  );
}

export default App;
