import "../App.scss";
import { Canvas } from "react-three-fiber";
import OpenSans from "../Open Sans ExtraBold_Regular.json";
import React, { useRef } from "react";
import {
  softShadows,
  OrbitControls,
  Text3D,
} from "@react-three/drei";
import Header from "./Header";
// import * as THREE from "three";
import { A } from './A';
import { Chill } from './Chill';
import { Ln } from "./Ln";
import { Hardies } from "./Hardies";
import { Mirror } from "./Mirror";


softShadows();


const DescriptOne = () => {
  const mesh = useRef(null);

  return (
    <mesh position={[63, -6, 105]} ref={mesh}>
      <Text3D font={OpenSans} size={3} onClick={handleClickA}>
        My old portfolio!
        <meshBasicMaterial color="black" />
      </Text3D>
    </mesh>
  );
};

 function handleClickA() {
   window.location.href = "https://www.adonis.earth/";
 }

 function handleClickC() {
   window.location.href = "https://www.c-h-i-l-l.us/";
 }

function handleClickLn() {
   window.location.href = "https://www.lumianocito.com/";
 }

 function handleClickH() {
   window.location.href = "https://www.hardieshardware.com/";
 }


  function handleClickM() {
   window.location.href = "https://vimeo.com/656724782";
 }


const DescriptTwo = () => {
  const mesh = useRef(null);

  return (
    <mesh position={[11, -5, 69]} ref={mesh}>
      <Text3D font={OpenSans} size={3} onClick={handleClickLn}>
        {` A photo portfolio I built for photographer, Lumia Nocito. \nTechnologies used, React, React Router Dom, Styled Components, \nDevice Detect, Python. Deployed with Vercel.`}
        <meshBasicMaterial color="black" />
      </Text3D>
    </mesh>
  );
};

const DescriptThree = () => {
  const mesh = useRef(null);

  return (
    <mesh position={[-89, -5, 35]} ref={mesh}>
      <Text3D font={OpenSans} smooth={4} size={3} onClick={handleClickC}>
        {`A live chat site I built for a clothing brand. It includes \ndifferent interactivities, primarily pertaining to the chat. Messages \nare sent and received in realtime. Technologies used, React, CSS, Firebase. \nDeployed with Vercel.`}
        <meshBasicMaterial color="black" />
      </Text3D>
    </mesh>
  );
};

const DescriptFour = () => {
  const mesh = useRef(null);

  return (
    <mesh position={[-74, -5, 90]} ref={mesh}>
      <Text3D font={OpenSans} smooth={4} size={3} onClick={handleClickH}>
        {`A webstore I built for Pro Skateboarder \nTyshawn Jones' brand, Hardies Hardware. \nTechnologies used, Shopify Liquid, HTML, CSS.`}
        <meshBasicMaterial color="black" />
      </Text3D>
    </mesh>
  );
};

const DescriptFive = () => {
  const mesh = useRef(null);

  return (
    <mesh position={[-55, -10, 0]} ref={mesh}>
      <Text3D font={OpenSans} smooth={4} size={3} onClick={handleClickM}>
        {`A video shot to showcase my Physical Computing final. \nAn NFT Smart Mirror, loaded with \nmodules, including a custom module to display the NFT. \nTechnologies used, HTML, CSS, JS, Python, Raspberry Pi.`}
        <meshBasicMaterial color="black" />
      </Text3D>
    </mesh>
  );
};


const Projs = () => {
  return (
    <>
      <Header />
      <Canvas colorManagement camera={{ position: [0, 0, 20], fov: 150 }}>
        {/* This light makes things look pretty */}
        <ambientLight intensity={1.3} />
        {/* Our main source of light, also casting our shadow */}
        <directionalLight
          castShadow
          position={[0, 10, 0]}
          intensity={1.5}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-camera-far={50}
          shadow-camera-left={-10}
          shadow-camera-right={10}
          shadow-camera-top={10}
          shadow-camera-bottom={-10}
        />
        {/* A light to help illumnate the spinning boxes */}
        <pointLight position={[-10, 0, -20]} intensity={0.5} />
        <pointLight position={[0, -10, 0]} intensity={1.5} />
        <group>
          {/* This mesh is the plane (The floor) */}
          <mesh
            rotation={[-Math.PI / 2, 0, 0]}
            position={[0, -3, 0]}
            receiveShadow
          >
            <planeBufferGeometry attach="geometry" args={[100, 100]} />
            <shadowMaterial attach="material" opacity={0.3} />
          </mesh>

          <group>
            <A onClick={handleClickA} position={[80, 15, 105]} />
            <DescriptOne />
          </group>

          <group>
            <Chill onClick={handleClickC} position={[-38, 3, 35]} />
            <DescriptThree />
          </group>

          <group>
            <Ln onClick={handleClickLn} position={[60, 5, 69]} />
            <DescriptTwo />
          </group>

          <group>
            <Hardies onClick={handleClickH} position={[-38, 3, 85]} />
            <DescriptFour />
          </group>

          <group>
            <Mirror onClick={handleClickM} />
            <DescriptFive />
          </group>
        </group>
        <OrbitControls />
      </Canvas>
    </>
  );
};

export default Projs;
