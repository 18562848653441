/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "react-three-fiber";

export function Ln(props) {
  const { nodes, materials } = useGLTF("/lumia.glb");
  const groupRef = useRef();
  const [rotation, setRotation] = useState(0);

  useFrame(() => {
    // Update the rotation in each frame
    setRotation((rotation) => rotation + 0.01);
  });

  return (
    <group ref={groupRef} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Curve.geometry}
        material={materials["SVGMat.011"]}
        position={[1.8, 1.74, 1.7]}
        rotation={[1.58, rotation, 0]}
        scale={101.64}
      />
    </group>
  );
}

useGLTF.preload("/lumia.glb");
