import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

export function Mirror(props) {
  const { nodes, materials } = useGLTF("/Mirror.glb");
  const groupRef = useRef();

  useFrame((state, delta) => {
    groupRef.current.rotation.y += 1.1 * Math.PI * delta;
  });

  return (
    <group ref={groupRef} {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["Node-Mesh"].geometry}
        material={materials.phong2SG}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["Node-Mesh_1"].geometry}
        material={materials.lambert8SG}
      />
    </group>
  );
}

useGLTF.preload("/Mirror.glb");
