import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "react-three-fiber";

export function A(props) {
  const { nodes, materials } = useGLTF("/a-3d.glb");
  const groupRef = useRef();
  let bounce = 0;
  const amplitude = 0.3; // Adjust this value to change the height of the bounce

  useFrame((state, delta) => {
    // Calculate the new position based on a sine wave
    bounce += delta * 6; // Adjust this value to change the speed of the bounce
    const newPosition = Math.sin(bounce) * amplitude;
    groupRef.current.position.y = newPosition;
  });

  return (
    <group ref={groupRef} {...props} dispose={null}>
      <group
        position={[0.05, 1.73, 0.06]}
        rotation={[1.57, 0, 0]}
        scale={18.32}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve002_1.geometry}
          material={materials["SVGMat.003"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve002_2.geometry}
          material={materials["SVGMat.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve002_3.geometry}
          material={materials["SVGMat.004"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/a-3d.glb");
